import { createSlice } from '@reduxjs/toolkit';
import {
	fetchQuestionarioPageDetails,
	sendDocumentGeneratedToSournce,
	uploadAndSendSurveyDetails,
	uploadSurveyDetails,
	uploadSurveyNote,
} from './questionario.actions';
import { questionarioPageDetails } from './questionario.initialState';

export const questionarioSlice = createSlice({
	name: 'questionarioPage',
	initialState: questionarioPageDetails,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(fetchQuestionarioPageDetails.fulfilled, (state, action) => {
			state.bannerDetail = action.payload.bannerDetail;
			state.documents = action.payload.documents;
			state.surveyDetails = action.payload.surveyDetails;
		});
		builder.addCase(uploadSurveyDetails.fulfilled, (state, action) => {
			const surveyData = action.payload.data;
			state.bannerDetail = surveyData.bannerDetail;
			state.documents = surveyData.documents;
			state.surveyDetails = surveyData.surveyDetails;
		});
		builder.addCase(uploadAndSendSurveyDetails.fulfilled, (state, action) => {
			const surveyData = action.payload.data;
			state.bannerDetail = surveyData.bannerDetail;
			state.documents = surveyData.documents;
			state.surveyDetails = surveyData.surveyDetails;
		});
		builder.addCase(sendDocumentGeneratedToSournce.fulfilled, (state, action) => {
			const surveyData = action.payload.data;
			state.bannerDetail = surveyData.bannerDetail;
			state.documents = surveyData.documents;
			state.surveyDetails = surveyData.surveyDetails;
		});
		builder.addCase(uploadSurveyNote.fulfilled, (state, action) => {
			const surveyData = action.payload.data;
			state.bannerDetail = surveyData.bannerDetail;
			state.documents = surveyData.documents;
			state.surveyDetails = surveyData.surveyDetails;
		});
	},
});

export const { reducer: questionarioReducer } = questionarioSlice;
