import {
	GENERATE_OTP_URL,
	GET_FILE_BASE64_FROM_MINIO_URL_FOR_LOGIN,
	GET_LOGIN_OPTION,
	IS_LOGIN_URL,
	LOGIN_URL,
	LOGIN_WITh_SPID_URL,
	LOGOUT_URL,
	SESSION_EXPIRED,
} from '../../../constant/apiUrls';
import { httpClient } from '../../../service/httpClient';
import { FileBase64Model, LoginSpid, OneTimeTokenResponse } from './login.type';

export const getLoginOption = async (accessToken: string) => {
	return httpClient.get(GET_LOGIN_OPTION, { params: { accessToken } });
};

export const loginWithOtp = async (otp: string) => {
	return httpClient.get(LOGIN_URL, { params: { otp } });
};

export const loginWithSpid = async (spidDetails: LoginSpid) => {
	return httpClient.post<OneTimeTokenResponse>(LOGIN_WITh_SPID_URL, spidDetails);
};

export const isLoggIn = async () => {
	return httpClient.get(IS_LOGIN_URL);
};
export const generateOTP = async (phoneNo: string) => {
	return httpClient.get(GENERATE_OTP_URL, { params: { phoneNo } });
};

export const logout = async () => {
	return httpClient.get(LOGOUT_URL);
};

export const sessionExpired = async () => {
	return httpClient.get(SESSION_EXPIRED);
};

export const getFileBase64FromMinio = async (files: FileBase64Model) => {
	return httpClient.post(GET_FILE_BASE64_FROM_MINIO_URL_FOR_LOGIN, files);
};
