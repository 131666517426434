import axios, { AxiosInstance } from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL || '/api/';

const instance: AxiosInstance = axios.create({
	baseURL,
	headers: {
		'Cache-Control': 'no-cache',
		Pragma: 'no-cache',
	},
});

export default instance;
