import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
	fetchUploadPageDetails,
	sendDataToSourceSytemAction,
	updateFileDetailsAction,
	uploadFileDetailsAction,
} from './upload.actions';
import { uploadPageDetails } from './upload.initialState';
import { SelectedSubrrogatingDocDetails, UploadModel } from './upload.type';

export const uploadSlice = createSlice({
	name: 'uploadPage',
	initialState: uploadPageDetails,
	reducers: {
		setfileToUpload(state: UploadModel, action: PayloadAction<File[]>) {
			state.uploadedFile = action.payload;
			return state;
		},
		setSelectedSubrrogatingDocCode(
			state: UploadModel,
			action: PayloadAction<SelectedSubrrogatingDocDetails>,
		) {
			state.staticUpload.documents
				.filter(g => g.documentIdentifyingCode === action.payload.parentDocIdentifyingCode)
				.forEach(f => {
					f.selectSubrogatingDocument =
						action.payload.selectedSubrrogatingDocCode !== 'default'
							? action.payload.selectedSubrrogatingDocCode
							: '';
				});
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchUploadPageDetails.fulfilled, (state, action) => {
			state.bannerDetail = action.payload.bannerDetail;
			state.staticUpload = action.payload.staticUpload;
			state.dynamicUpload = action.payload.dynamicUpload;
			state.dynamicUploadDetails = action.payload.dynamicUploadDetails;
		});

		builder.addCase(uploadFileDetailsAction.fulfilled, (state, action) => {
			state.bannerDetail = action.payload.data.bannerDetail;
			state.staticUpload = action.payload.data.staticUpload;
			state.dynamicUpload = action.payload.data.dynamicUpload;
			state.dynamicUploadDetails = action.payload.data.dynamicUploadDetails;
		});
		builder.addCase(updateFileDetailsAction.fulfilled, (state, action) => {
			state.bannerDetail = action.payload.data.bannerDetail;
			state.staticUpload = action.payload.data.staticUpload;
			state.dynamicUpload = action.payload.data.dynamicUpload;
			state.dynamicUploadDetails = action.payload.data.dynamicUploadDetails;
		});
		builder.addCase(sendDataToSourceSytemAction.fulfilled, (state, action) => {
			if (action.payload.isSuccess) {
				state.bannerDetail = action.payload.data.bannerDetail;
				state.staticUpload = action.payload.data.staticUpload;
				state.dynamicUpload = action.payload.data.dynamicUpload;
				state.dynamicUploadDetails = action.payload.data.dynamicUploadDetails;
			}
		});
	},
});

export const { reducer: uploadReducer } = uploadSlice;
export const { setfileToUpload, setSelectedSubrrogatingDocCode } = uploadSlice.actions;
