import { createAsyncThunk } from '@reduxjs/toolkit';
import * as selfIdPageAPI from './selfId.api';
import { AnswerData, PersonalDetailsData, SelfIdFileDetails } from './selfId.type';

export const fetchSelfIdPageDetails = createAsyncThunk('SelfId/GetSelfIdPageDetails', async () => {
	const resp = await selfIdPageAPI.getSelfIdPageDetails();
	return resp.data;
});

export const selfIdFileDetailsAction = createAsyncThunk(
	'selfId/UploadFilesAndDetails',
	async (formData: FormData) => {
		const resp = await selfIdPageAPI.uploadFileDetails(formData);
		return resp.data;
	},
);

export const uploadPersonalDetails = createAsyncThunk(
	'selfId/savePersonalDetails',
	async (formData: PersonalDetailsData) => {
		const resp = await selfIdPageAPI.savePersonalDetails(formData);
		return resp.data;
	},
);

export const sendIdentificationDataToSource = createAsyncThunk(
	'selfId/sendDataToSourceSystem',
	async (personalData: SelfIdFileDetails[]) => {
		const resp = await selfIdPageAPI.sendDataToSourceSystem(personalData);
		return resp.data;
	},
);

export const getQRCodeDetailsAction = createAsyncThunk('selfId/GetQRScanDetails', async () => {
	const resp = await selfIdPageAPI.getQRScanDetails();
	return resp.data;
});
