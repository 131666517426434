import {
	GET_FILE_DETAILS_FOR_VALIDAION,
	GET_NOTE_HISTORY,
	GET_UPLOAD_PAGE_DETAIL_URL,
	SEND_SECTION_DETAIL_TO_SOURCE_SYSTEM,
	UPDATE_FILE_DETAILS,
	UPLOAD_FILE_DETAILS,
} from '../../../constant/apiUrls';
import { httpClient } from '../../../service/httpClient';
import { UploadFileDetails } from './upload.type';

export const getUploadPageDetails = async () => {
	return httpClient.get(GET_UPLOAD_PAGE_DETAIL_URL);
};

export const getFileDetailsForValidation = async (formData: FormData) => {
	return httpClient.post(GET_FILE_DETAILS_FOR_VALIDAION, formData);
};

export const uploadFileDetails = async (formData: FormData) => {
	return httpClient.post(UPLOAD_FILE_DETAILS, formData);
};

export const updateFileDetails = async (filedata: UploadFileDetails) => {
	return httpClient.post(UPDATE_FILE_DETAILS, filedata);
};

export const sendDataTOSourceSystem = async (uploadSectionModule: UploadFileDetails[]) => {
	return httpClient.post(SEND_SECTION_DETAIL_TO_SOURCE_SYSTEM, uploadSectionModule);
};

export const getNoteHistoryByIssueId = async (documentIdentifyingCode: string) => {
	return httpClient.get(GET_NOTE_HISTORY, { params: { documentIdentifyingCode } });
};
