import axiosInstance from './axiosInstance';

interface ResopnseError {
	errorInnerException: string;
	errorMessage: string;
	errorStackTrace: string;
	responseStatusCode: number;
}

axiosInstance.interceptors.response.use(
	response => {
		return response;
	},
	function (errorRes) {
		if (errorRes.response?.status === 401) {
			window.location.href = errorRes.response.data.redirectUrlonSessionExpiry;
		}

		// showNotification({
		// 	type: 'danger',
		// 	title: `${errorRes.response?.statusText} - ${errorRes.response?.status}`,
		// 	message: `${errorRes.response?.data?.errorMessage} ${errorRes.response?.data?.errorStackTrace}`,
		// });

		return Promise.reject(errorRes);
	},
);

export default axiosInstance;
