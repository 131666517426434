import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Question } from '../layout';
import { fetchWelcomePageDetails } from './welcome.actions';
import { welcomePageDetails } from './welcome.initialState';
import { WelcomeModel } from './welcome.type';

export const welcomeSlice = createSlice({
	name: 'welcomePage',
	initialState: welcomePageDetails,
	reducers: {
		setCardConfig(state: WelcomeModel, action: PayloadAction<Question>) {
			if (
				state.cardDetails.length >
				Number(action.payload.questionModelData.welcomeCard.numberOfCards)
			) {
				state.cardDetails.splice(
					Number(action.payload.questionModelData.welcomeCard.numberOfCards) - 1,
					state.cardDetails.length -
						Number(action.payload.questionModelData.welcomeCard.numberOfCards),
				);
			}
			state.cardDetails.forEach(es => {
				if (
					es.rows.length >
					Number(action.payload.questionModelData.welcomeCard.numberOfFieldsPerCard)
				) {
					es.rows.splice(
						Number(action.payload.questionModelData.welcomeCard.numberOfFieldsPerCard) -
							1,
						es.rows.length -
							Number(
								action.payload.questionModelData.welcomeCard.numberOfFieldsPerCard,
							),
					);
				}
			});
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchWelcomePageDetails.fulfilled, (state, action) => {
			state.bannerDetail = action.payload.bannerDetail;
			state.cardDetails = action.payload.cardDetails;
		});
	},
});

export const { reducer: welcomeReducer } = welcomeSlice;

export const { setCardConfig } = welcomeSlice.actions;
