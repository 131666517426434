import DocViewer, { DocViewerRenderers, IDocument } from '@chalkbooks/react-doc-viewer';
import { base64ToBlob, downloadfile, getMimeType } from './UtilityFunctionsComponent';
import { FileDetail } from '../../store/slice/download';
import { useEffect } from 'react';
import { FileDetailForLogin } from '../../store/slice';

interface Props {
	d: FileDetail[] | FileDetailForLogin[];
}
const FilePreviewComponent = ({ d }: Props) => {
	let element: HTMLAnchorElement | null;
	let includesPdf = false;
	const allPreviewableDocs: IDocument[] = d
		.filter(fs => fs.fileBase64)
		.map(m => {
			const data: IDocument = {
				uri: window.URL.createObjectURL(
					base64ToBlob(m.fileBase64, getMimeType(m.fileType)),
				),
				fileData: m.fileBase64 || '',
				fileType: m.fileType || '',
				fileName: m.fileName + '.' + m.fileType,
			};
			if (m.fileType == 'pdf') {
				includesPdf = true;
			}
			return data;
		});

	const removeAnchor = (newEle: HTMLAnchorElement) => {
		element = newEle;
		if (element) {
			const filename = element.getAttribute('download') ?? '';
			element.removeAttribute('href');
			element.addEventListener(
				'click',
				function () {
					downloadfile(allPreviewableDocs, filename);
				},
				false,
			);
		}
	};

	useEffect(() => {
		if (includesPdf) {
			const myInterval = setInterval(() => {
				const newEle = document.getElementById('pdf-download') as HTMLAnchorElement;
				if (element?.getAttribute('download') !== newEle?.getAttribute('download')) {
					removeAnchor(newEle);
				}
			}, 1000);
			return () => {
				clearInterval(myInterval);
			};
		}
	}, []);

	return (
		<DocViewer
			config={{
				header: {
					disableHeader: false,
					disableFileName: true,
				},
				pdfVerticalScrollByDefault: true,
				pdfZoom: { defaultZoom: 1, zoomJump: 1 },
				noRenderer: {},
			}}
			documents={allPreviewableDocs}
			pluginRenderers={DocViewerRenderers}
		/>
	);
};

export default FilePreviewComponent;
