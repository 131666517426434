import { createAsyncThunk } from '@reduxjs/toolkit';
import * as firmaPageAPI from './firma.api';
import { CriticalInfo } from './firma.type';

export const fetchFirmaPageDetails = createAsyncThunk('Firma/GetFirmaPageDetails', async () => {
	const resp = await firmaPageAPI.getFirmaPageDetails();
	return resp.data;
});

export const logCriticalInfoInDb = createAsyncThunk(
	'Firma/LogCriticalInfoAsync',
	async (info: CriticalInfo) => {
		const resp = await firmaPageAPI.logCriticalInfo(info);
		return resp.data;
	},
);
