import { Loader as Spinner } from 'mida-core-ui/components';

function Loader() {
	return (
		<div className="z-50 fixed bg-brand-primary-color bg-opacity-20 pos min-h-screen min-w-full flex items-center justify-center">
			<Spinner className="h-10 w-10 text-brand-primary-color" />
		</div>
	);
}

export default Loader;
