import { createSlice } from '@reduxjs/toolkit';
import { fetchTrackingPageDetails } from './tracking.actions';
import { trackingPageDetails } from './tracking.initialState';

export const trackingSlice = createSlice({
	name: 'trackingPage',
	initialState: trackingPageDetails,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(fetchTrackingPageDetails.fulfilled, (state, action) => {
			state.bannerDetail = action.payload.bannerDetail;
			state.trackingProgressDetails = action.payload.trackingProgressDetails;
		});
	},
});

export const { reducer: trackingReducer } = trackingSlice;
