import { PropsWithChildren } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuthorise from '../hooks/useAuthorise';

export interface ProtectedRouteProps {
	redirectPath?: string;
}

const ProtectedRoute = ({
	redirectPath = '/sessionExpired',
	children,
}: PropsWithChildren<ProtectedRouteProps>) => {
	const isLoggIn = useAuthorise();
	return isLoggIn ? <Outlet /> : <Navigate to={redirectPath} replace />;
};

export default ProtectedRoute;
