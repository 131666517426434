import { createAsyncThunk } from '@reduxjs/toolkit';
import * as loginAPI from './login.api';
import { FileBase64Model, LoginSpid } from './login.type';

export const fetchLoginOption = createAsyncThunk(
	'Login/GetLoginOption',
	async (accessToken: string) => {
		const resp = await loginAPI.getLoginOption(accessToken);
		return resp.data;
	},
);

export const loginWithOtpAsync = createAsyncThunk('Login/LoginWithOtp', async (otp: string) => {
	const resp = await loginAPI.loginWithOtp(otp);
	return resp.data;
});

export const loginWithSpidAsync = createAsyncThunk(
	'Login/LoginWithSpid',
	async (spidDetails: LoginSpid) => {
		const resp = await loginAPI.loginWithSpid(spidDetails);
		return resp.data;
	},
);

export const fetchIssLoggin = createAsyncThunk('Login/IsLoggIn', async () => {
	const resp = await loginAPI.isLoggIn();
	return resp.data;
});

export const generateOTPAsync = createAsyncThunk('Login/GenerateOTP', async (phoneNo: string) => {
	const resp = await loginAPI.generateOTP(phoneNo);
	return resp.data;
});

export const logoutFormApplication = createAsyncThunk('Login/Logout', async () => {
	const resp = await loginAPI.logout();
	return resp.data;
});

export const sessionExpiredFormApplication = createAsyncThunk('Login/SessionExpired', async () => {
	const resp = await loginAPI.sessionExpired();
	return resp.data;
});

export const getFileBase64ForLoginFromMinioAction = createAsyncThunk(
	'SourceSystemDataSubmission/getFileBase64FromMinioAction',
	async (file: FileBase64Model) => {
		const resp = await loginAPI.getFileBase64FromMinio(file);
		return resp.data;
	},
);
