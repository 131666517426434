import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchDownloadPageDetails, updateDownloadModuleStatus } from './download.actions';
import { downloadPageDetails } from './download.initialState';
import { DownloadModel, IDOcStatus } from './download.type';

export const downloadSlice = createSlice({
	name: 'downloadPage',
	initialState: downloadPageDetails,
	reducers: {
		setDocumentStatus(state: DownloadModel, action: PayloadAction<IDOcStatus>) {
			state.fileDetails.forEach(f => {
				if (
					f.documentCategory === action.payload.fileDetails.documentCategory &&
					f.docType === action.payload.fileDetails.docType &&
					f.documentIdentifyingCode === action.payload.fileDetails.documentIdentifyingCode
				) {
					f.docStatus.forEach(g => {
						if (g.key === action.payload.statusName && !g.value) g.value = !g.value;
					});
				}
			});
			return state;
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchDownloadPageDetails.fulfilled, (state, action) => {
			state.bannerDetail = action.payload.bannerDetail;
			state.fileDetails = action.payload.fileDetails;
		});
		builder.addCase(updateDownloadModuleStatus.fulfilled, (state, action) => {
			state.bannerDetail = action.payload.databannerDetail;
			state.fileDetails = action.payload.data.fileDetails;
		});
	},
});

export const { reducer: downloadReducer } = downloadSlice;
export const { setDocumentStatus } = downloadSlice.actions;
