import { useSelector } from 'react-redux';
import { RootState } from '../store';

const useAuthorise = () => {
	const isAuthorise: boolean = useSelector(
		(state: RootState) => state.login.loginDetails.data?.isLoggIn ?? false,
	);

	//const path = localStorage.getItem(redirectUrlKey);
	//if (path !== '/' && path !== null) {
	//	localStorage.removeItem(redirectUrlKey);
	//	window.location.href = path;
	//}
	return isAuthorise;
};

export default useAuthorise;
