import { createAsyncThunk } from '@reduxjs/toolkit';
import * as questionarioPageAPI from './questionario.api';
import { NoteRequest, SurveyDetailsData, SurveyFileDetails } from './questionario.type';

export const fetchQuestionarioPageDetails = createAsyncThunk(
	'Questionario/GetQuestionarioPageDetails',
	async () => {
		const resp = await questionarioPageAPI.getQuestionarioPageDetails();
		return resp.data;
	},
);

export const uploadSurveyDetails = createAsyncThunk(
	'Questionario/saveSurveyDetails',
	async (formData: SurveyDetailsData) => {
		const resp = await questionarioPageAPI.saveSurveyDetails(formData);
		return resp.data;
	},
);

export const sendDocumentGeneratedToSournce = createAsyncThunk(
	'Questionario/SendDocument',
	async (formData: SurveyFileDetails) => {
		const resp = await questionarioPageAPI.SendDocument(formData);
		return resp.data;
	},
);

export const uploadAndSendSurveyDetails = createAsyncThunk(
	'Questionario/saveAndSendSurveyDetails',
	async (formData: SurveyDetailsData) => {
		const resp = await questionarioPageAPI.saveAndSendSurveyDetails(formData);
		return resp.data;
	},
);

export const uploadSurveyNote = createAsyncThunk(
	'Questionario/saveSurveyNotes',
	async (surveyNote: NoteRequest) => {
		const resp = await questionarioPageAPI.saveSurveyNotes(surveyNote);
		return resp.data;
	},
);

export const getNoteHistoryByDocumentCodeIdAction = createAsyncThunk(
	'Questionario/getNoteHistoryByDocumentCodeId',
	async (documentIdentifyingCode: string) => {
		const resp = await questionarioPageAPI.getNoteHistoryByDocumentCodeId(
			documentIdentifyingCode,
		);
		return resp.data;
	},
);
