import { NOTIFICATION_TYPE } from 'react-notifications-component';
import { NOTIFICATION_CONTAINER, Store } from 'react-notifications-component';

export interface Notification {
	message: string;
	container?: NOTIFICATION_CONTAINER;
	title?: string;
	type?: NOTIFICATION_TYPE;
}

export const showNotification = (data: Notification): void => {
	Store.addNotification({
		title: data?.title,
		message: data.message,
		type: data?.type || 'success',
		insert: 'top',
		container: data?.container || 'top-right',
		animationIn: ['animate__animated', 'animate__fadeIn'],
		animationOut: ['animate__animated', 'animate__fadeOut'],
		dismiss: {
			duration: 5000,
			onScreen: true,
			pauseOnHover: true,
			showIcon: true,
		},
	});
};
