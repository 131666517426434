import 'mida-core-ui/icons/index.css';
import 'mida-core-ui/style.css';
import ReactDOM from 'react-dom/client';
import 'react-notifications-component/dist/theme.css';
import { Provider } from 'react-redux';
import AppWrapper from './AppWrapper';
import './index.css';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={store}>
		{/* <Suspense fallback={<Loader />}> */}
		<AppWrapper />
		{/* </Suspense> */}
	</Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
