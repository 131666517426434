import { UploadModel } from './upload.type';

export const uploadPageDetails: UploadModel = {
	bannerDetail: {
		title: null,
		description: null,
	},
	staticUpload: {
		documents: [],
	},
	dynamicUpload: {
		documents: [],
	},
	dynamicUploadDetails: [],
};
