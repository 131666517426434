import { LayoutDeatilModel } from './layout.type';

export const layoutDetails: LayoutDeatilModel = {
	sourceId: 0,
	processId: 0,
	headerDeatil: {
		headerTitle: null,
		bankName: null,
	},
	sideBarDetail: [],
	modules: [],
	bannerDetail: {
		title: null,
		description: null,
	},
	isInternalOrExternalAgent: false,
	customers: [],
	selectedCustomerExternalPersonId: '',
};
