import { PropsWithChildren } from 'react';

interface ConfirmDailogProps {
	title: string;
	onClose: () => void;
}

function ConfirmDailog({ onClose, title, children }: PropsWithChildren<ConfirmDailogProps>) {
	return (
		<div
			className="relative z-40"
			aria-labelledby="modal-title"
			role="dialog"
			aria-modal="true">
			<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
			<div className="fixed inset-0 z-10 overflow-y-auto">
				<div className="flex max-h-screen w-full items-end justify-end p-0 text-center sm:items-center">
					<div className="relative h-screen transform overflow-scroll bg-white text-left shadow-xl transition-all w-3/6 p-4">
						<div className="flex items-center border-b pb-4 text-lg font-bold">
							<span
								title="Back"
								className="font-bold text-2xl inline-block pr-2 hover:cursor-pointer"
								onClick={onClose}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="currentColor"
									className="w-6 h-6">
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M8.25 4.5l7.5 7.5-7.5 7.5"
									/>
								</svg>
							</span>
							<span className="text-brand-secondary-color">{title}</span>
						</div>
						<div className="p-2 h-full">{children}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ConfirmDailog;
