import { FirmaModel } from './firma.type';

export const getFirmaPageDetails: FirmaModel = {
	title: '',
	bannerDetail: {
		description: '',
		title: '',
	},
	documents: [],
};
