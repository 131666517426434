import React, { PropsWithChildren } from 'react';
import crossIcon from '../../assets/images/cross-grey-icon.svg';
interface ConfirmDailogProps {
	title: string;
	onClose: () => void;
}

function ConfirmdialogResponsive({
	onClose,
	title,
	children,
}: PropsWithChildren<ConfirmDailogProps>) {
	return (
		<div className=" z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
			{/* <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div> */}
			<div className="fixed inset-0 z-40 overflow-y-auto">
				<div className="flex max-h-screen w-full items-end justify-end p-0 text-center sm:items-center">
					<div className="relative h-screen transform overflow-scroll bg-white text-left shadow-xl transition-all w-full p-4">
						<div className="flex items-center border-b pb-4 text-lg font-bold justify-between">
							<span className="text-brand-secondary-color">{title}</span>
							<span
								title="Back"
								className="font-bold text-2xl inline-block pr-2 hover:cursor-pointer"
								onClick={onClose}>
								<img className="hover:cursor-pointer h-6 w-6" src={crossIcon} />
							</span>
						</div>
						<div className="p-2 h-full">{children}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ConfirmdialogResponsive;
