import {
	GET_LAYOUT_DETAIL_URL,
	SET_ExternalPersonIdInCaseOfInternalAndExternalAgent,
} from '../../../constant/apiUrls';
import { httpClient } from '../../../service/httpClient';
import { PersonResponse } from './layout.type';

const userAuthUrl = '/login';
export const getLayoutDetails = async () => {
	return httpClient.get(GET_LAYOUT_DETAIL_URL);
};

export const ssoAuthUrl = async () => {
	return httpClient.get(userAuthUrl);
};

export const setExternalPersonIdInCaseOfInternalAndExternalAgent = async (
	externalPerson: PersonResponse,
) => {
	return httpClient.get(SET_ExternalPersonIdInCaseOfInternalAndExternalAgent, {
		params: {
			externalPersonIdInCaseOfInternalAndExternalAgent: externalPerson.externalPerson_ID,
			accessToken: externalPerson.accessToken,
			accessTokenId: externalPerson.accessTokenId,
		},
	});
};
