import {
	DOWNLOAD_SIGNED_DOCUMENT_TO_NAMIRIAL_ASYNC_URL,
	NAMIRIAL_CALLBACK_LOOP_FOR_SINGLE_SIGNER_COMPLETION_ASYNC_URL,
	NAMIRIAL_CALLBACK_WORKFLOW_COMPLETED_ASYNC_URL,
	SEND_ENVELOP_TO_NAMIRIAL_ASYNC_URL,
	SIGN_DOC_IN_NAMIRIAL_ASYNC_URL,
	UPLOAD_FILE_TO_NAMIRIAL_ASYNC_URL,
	VIEW_ENVELOP_TO_NAMIRIAL_ASYNC_URL,
} from '../../../constant/apiUrls';
import { httpClient } from '../../../service/httpClient';
import { FirmaFileModel } from '../firma';
import { UploadFileRequest } from './namirial.type';

export const uploadFileToNamirialAsync = async (uploadReq: UploadFileRequest) => {
	return httpClient.post(UPLOAD_FILE_TO_NAMIRIAL_ASYNC_URL, uploadReq);
};

export const SendEnvelopToNamirialAsync = async (sendEnvolpReq: UploadFileRequest) => {
	return httpClient.post(SEND_ENVELOP_TO_NAMIRIAL_ASYNC_URL, sendEnvolpReq);
};

export const ViewEnvelopToNamirialAsync = async (viewEnvelopRes: UploadFileRequest) => {
	return httpClient.post(VIEW_ENVELOP_TO_NAMIRIAL_ASYNC_URL, viewEnvelopRes);
};

export const DownloadSignedDocumentToNamirialAsync = async (
	downloadSignedDocumentReq: UploadFileRequest,
) => {
	return httpClient.post(
		DOWNLOAD_SIGNED_DOCUMENT_TO_NAMIRIAL_ASYNC_URL,
		downloadSignedDocumentReq,
	);
};

export const SignDocInNamirialAsync = async (uploadReq: FirmaFileModel[]) => {
	return httpClient.post(SIGN_DOC_IN_NAMIRIAL_ASYNC_URL, uploadReq);
};

export const downlaodSignedDocument = (envelope: string) => {
	return httpClient.get(NAMIRIAL_CALLBACK_WORKFLOW_COMPLETED_ASYNC_URL, {
		params: {
			processId: 0,
			issueId: 'jf',
			personId: 'wd',
			accessToken: '1cc749d7-d0f4-441d-b757-701ba0876a6e',
			envelope,
		},
	});
};

export const getMultiSignerViewLinkIfCuurentEnvelopCompleted = async (
	singleSignerEnvelop: string,
	multiSignerEnvelop: string,
) => {
	return httpClient.get(NAMIRIAL_CALLBACK_LOOP_FOR_SINGLE_SIGNER_COMPLETION_ASYNC_URL, {
		params: {
			singleSignerEnvelop,
			multiSignerEnvelop,
		},
	});
};
