import { GET_FIRMA_PAGE_DETAIL_URL, LOG_CRITICAL_INFO_URL } from '../../../constant/apiUrls';
import { httpClient } from '../../../service/httpClient';
import { CriticalInfo } from './firma.type';

export const getFirmaPageDetails = async () => {
	return httpClient.get(GET_FIRMA_PAGE_DETAIL_URL);
};

export const logCriticalInfo = async (info: CriticalInfo) => {
	return httpClient.post(LOG_CRITICAL_INFO_URL, info);
};
