import { createAsyncThunk } from '@reduxjs/toolkit';
import * as welcomePageAPI from './welcome.api';

export const fetchWelcomePageDetails = createAsyncThunk(
	'Welcome/GetWelcomePageDetails',
	async () => {
		const resp = await welcomePageAPI.getWelcomePageDetails();
		return resp.data;
	},
);
