import { createAsyncThunk } from '@reduxjs/toolkit';
import * as downloadPageAPI from './download.api';
import { DocumentTraking, DownloadFileBase64Model, FileModel } from './download.type';

export const fetchDownloadPageDetails = createAsyncThunk(
	'Download/GetDownloadPageDetails',
	async () => {
		const resp = await downloadPageAPI.getDownloadPageDetails();
		return resp.data;
	},
);

export const setDownloadTrackingDetails = createAsyncThunk(
	'Download/setDownloadTrackingDetails',
	async (fileDetails: DocumentTraking) => {
		fileDetails.fileDetails.fileDetail.fileBase64 = '';
		const resp = await downloadPageAPI.SetDocumentTracking(fileDetails);
		return resp.data;
	},
);

export const updateDownloadModuleStatus = createAsyncThunk(
	'Download/updateDownloadModuleStatus',
	async (fileDetails: FileModel) => {
		fileDetails.fileDetail.fileBase64 = '';
		const resp = await downloadPageAPI.UpdateDownloadModuleStatus(fileDetails);
		return resp.data;
	},
);

export const sendDownloadModuleDataOnEach = createAsyncThunk(
	'SourceSystemDataSubmission/SendDownloadModuleDataOnEachAction',
	async (actionPerformed: string) => {
		const resp = await downloadPageAPI.sendDownloadModuleEachActionToSourceSyste(
			actionPerformed,
		);
		return resp.data;
	},
);

export const getFileBase64FromMinioAction = createAsyncThunk(
	'SourceSystemDataSubmission/getFileBase64FromMinioAction',
	async (file: DownloadFileBase64Model) => {
		const resp = await downloadPageAPI.getFileBase64FromMinio(file);
		return resp.data;
	},
);
