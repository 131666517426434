import { createAsyncThunk } from '@reduxjs/toolkit';
import * as trackingPageAPI from './tracking.api';

export const fetchTrackingPageDetails = createAsyncThunk(
	'Tracking/GetTrackingPageDetails',
	async () => {
		const resp = await trackingPageAPI.getTrackingPageDetails();
		return resp.data;
	},
);
