import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { fetchLayoutDetails } from './layout.actions';
import { layoutDetails } from './layout.initialState';
import { BannerModel, LayoutDeatilModel } from './layout.type';

export const layoutSlice = createSlice({
	name: 'login',
	initialState: layoutDetails,
	reducers: {
		setHeaderDetails(state: LayoutDeatilModel, action: PayloadAction<string>) {
			state.headerDeatil.headerTitle = action.payload;
		},
		setBannerDetails(state: LayoutDeatilModel, action: PayloadAction<BannerModel>) {
			state.bannerDetail = action.payload;
		},
		setHeaderBankLogo(state: LayoutDeatilModel, action: PayloadAction<string>) {
			state.headerDeatil.bankName = action.payload;
		},
		setCustomerExternalPersonIdInCaseOfInternalAndExternalAgentAtReducer(
			state: LayoutDeatilModel,
			action: PayloadAction<string>,
		) {
			state.selectedCustomerExternalPersonId = action.payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchLayoutDetails.fulfilled, (state, action) => {
			state.sourceId = action.payload.sourceId;
			state.processId = action.payload.processId;
			state.sideBarDetail = action.payload.sideBarDetail;
			state.headerDeatil = action.payload.headerDeatil;
			state.modules = action.payload.modules;
			state.customers = action.payload.customers;
			state.isInternalOrExternalAgent = action.payload.isInternalOrExternalAgent;
			state.selectedCustomerExternalPersonId =
				action.payload.selectedCustomerExternalPersonId;
		});
	},
});
export const {
	setHeaderDetails,
	setBannerDetails,
	setHeaderBankLogo,
	setCustomerExternalPersonIdInCaseOfInternalAndExternalAgentAtReducer,
} = layoutSlice.actions;
export const { reducer: layoutReducer } = layoutSlice;
