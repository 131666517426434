import { createSlice } from '@reduxjs/toolkit';

import { getMultiSignerViewLinkIfCuurentEnvelopCompletedAction } from './namirial.actions';
import { singDocRes } from './namirial.initialState';

export const namirialSlice = createSlice({
	name: 'namirial',
	initialState: singDocRes,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(getMultiSignerViewLinkIfCuurentEnvelopCompletedAction.fulfilled, (s, p) => {
			if (p.payload.isSuccess && p.payload.data.viewerLinks) {
				s.isMultiSignerModelOpenedByCallBack = true;
				s.viewerLinks = p.payload.data.viewerLinks;
			}
		});
	},
});

export const { reducer: namirialReducer } = namirialSlice;
