import { createAsyncThunk } from '@reduxjs/toolkit';
import * as layoutAPI from './layout.api';
import { PersonResponse } from './layout.type';

export const fetchLayoutDetails = createAsyncThunk('Layout/GetLayoutDeatil', async () => {
	const resp = await layoutAPI.getLayoutDetails();
	return resp.data;
});

export const ssoAuthUrlRedirect = createAsyncThunk('Layout/GetLayoutDeatil', async () => {
	const resp = await layoutAPI.ssoAuthUrl();
	return resp.data;
});

export const actionSetExternalPersonIdInCaseOfInternalAndExternalAgent = createAsyncThunk(
	'Layout/SetExternalPersonIdInCaseOfInternalAndExternalAgent',
	async (externalPerson: PersonResponse) => {
		const resp = await layoutAPI.setExternalPersonIdInCaseOfInternalAndExternalAgent(
			externalPerson,
		);
		return resp.data;
	},
);
