import {
	GET_QUESTIONARIO_PAGE_DETAIL_URL,
	GET_SURVEY_NOTE_HISTORY,
	SAVE_AND_UPLOAD_SURVEY_DATA,
	SAVE_QUESTION_SUREY_NOTE,
	SEND_DOCUMENT_TO_SOURCE,
	UPLOAD_SURVEY_DATA,
} from '../../../constant/apiUrls';
import { httpClient } from '../../../service/httpClient';
import { NoteRequest, SurveyDetailsData, SurveyFileDetails } from './questionario.type';

export const getQuestionarioPageDetails = async () => {
	return httpClient.get(GET_QUESTIONARIO_PAGE_DETAIL_URL);
};

export const saveSurveyDetails = async (SurveyData: SurveyDetailsData) => {
	return httpClient.post(UPLOAD_SURVEY_DATA, SurveyData);
};

export const saveAndSendSurveyDetails = async (SurveyData: SurveyDetailsData) => {
	return httpClient.post(SAVE_AND_UPLOAD_SURVEY_DATA, SurveyData);
};

export const SendDocument = async (surveyFileDetails: SurveyFileDetails) => {
	return httpClient.post(SEND_DOCUMENT_TO_SOURCE, surveyFileDetails);
};

export const saveSurveyNotes = async (surveyNote: NoteRequest) => {
	return httpClient.post(SAVE_QUESTION_SUREY_NOTE, surveyNote);
};

export const getNoteHistoryByDocumentCodeId = async (documentIdentifyingCode: string) => {
	return httpClient.get(GET_SURVEY_NOTE_HISTORY, { params: { documentIdentifyingCode } });
};
