import type { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { redirectUrlKey, userAuthUrl } from '../constant/appConstants';
import axiosInstance from '../service/axiosInstance';

export const useAxiosLoader = () => {
	const [counter, setCounter] = useState(0);
	const [interceptorsSetupComplete, setinterceptorsSetupComplete] = useState(false);
	useEffect(() => {
		const inc = (mod: number) => setCounter(c => c + mod);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const handleRequest = (request: InternalAxiosRequestConfig<any>) => {
			const urls: string[] = [
				'Namirial/GetMultiSignerViewLinkIfCuurentEnvelopCompleted',
				'upload/UploadFilesAndDetails',
				'upload/GetFileDetail',
			];
			if (!urls.includes(request.url ? request.url : '')) inc(1);
			return request;
		};
		const handleResponse = (response: AxiosResponse) => {
			if (response.headers.url !== 'Namirial/GetMultiSignerViewLinkIfCuurentEnvelopCompleted')
				inc(-1);
			return response;
		};
		const handleError = (error: AxiosError) => {
			if (error.config?.url !== 'Namirial/GetMultiSignerViewLinkIfCuurentEnvelopCompleted')
				inc(-1);
			if (error.code === 'ERR_NETWORK') {
				if (error.config?.url === 'login/Logout') {
					window.location.href = '/sessionExpired';
				} else {
					localStorage.setItem(redirectUrlKey, window.location.pathname);
					window.location.href = userAuthUrl;
				}
			}
		};
		const reqInterceptor = axiosInstance.interceptors.request.use(handleRequest, handleError);
		const resInterceptor = axiosInstance.interceptors.response.use(handleResponse, handleError);
		setinterceptorsSetupComplete(true);

		return () => {
			axiosInstance.interceptors.request.eject(reqInterceptor);
			axiosInstance.interceptors.response.eject(resInterceptor);
		};
	}, []);

	return [counter > 0, interceptorsSetupComplete];
};
