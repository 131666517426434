import {
	GET_DOWNLOAD_PAGE_DETAIL_URL,
	GET_FILE_BASE64_FROM_MINIO_URL,
	SET_DOCUMENT_STATUS_URL,
	SET_DOCUMENT_TRACKING_URL,
	SOURCE_SYSTEM_EACH_ACTION_URL,
} from '../../../constant/apiUrls';
import { httpClient } from '../../../service/httpClient';
import { DocumentTraking, DownloadFileBase64Model, FileModel } from './download.type';

export const getDownloadPageDetails = async () => {
	return httpClient.get(GET_DOWNLOAD_PAGE_DETAIL_URL);
};

export const SetDocumentTracking = async (fileDetails: DocumentTraking) => {
	return httpClient.post(SET_DOCUMENT_TRACKING_URL, fileDetails);
};

export const UpdateDownloadModuleStatus = async (fileDetails: FileModel) => {
	return httpClient.post(SET_DOCUMENT_STATUS_URL, fileDetails);
};

export const sendDownloadModuleEachActionToSourceSyste = async (actionPerformed: string) => {
	return httpClient.post(SOURCE_SYSTEM_EACH_ACTION_URL, actionPerformed);
};

export const getFileBase64FromMinio = async (files: DownloadFileBase64Model) => {
	return httpClient.post(GET_FILE_BASE64_FROM_MINIO_URL, files);
};
