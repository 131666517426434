import { createSlice } from '@reduxjs/toolkit';

import {
	fetchIssLoggin,
	fetchLoginOption,
	generateOTPAsync,
	loginWithOtpAsync,
	loginWithSpidAsync,
} from './login.actions';
import { loginOptionDetails } from './login.initialState';

export const loginSlice = createSlice({
	name: 'login',
	initialState: loginOptionDetails,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(fetchLoginOption.fulfilled, (state, action) => {
			const loginOptions = action.payload.data;
			state.loginOTPDetails.optionModel.isAccessTokenExpired =
				loginOptions.optionModel.isAccessTokenExpired;
			state.loginOTPDetails.optionModel.isAccessTokenInvalid =
				loginOptions.optionModel.isAccessTokenInvalid;
			state.loginOTPDetails.optionModel.isOTPLogin = loginOptions.optionModel.isOTPLogin;
			state.loginOTPDetails.optionModel.isSPIDLogin = loginOptions.optionModel.isSPIDLogin;
			state.loginOTPDetails.optionModel.isSSOLogin = loginOptions.optionModel.isSSOLogin;
			if (loginOptions.optionModel.authenticatorTypes) {
				state.loginOTPDetails.optionModel.authenticatorTypes.customerChoice =
					loginOptions.optionModel.authenticatorTypes.customerChoice;
				state.loginOTPDetails.optionModel.authenticatorTypes.otpMethod =
					loginOptions.optionModel.authenticatorTypes.otpMethod;
				state.loginOTPDetails.optionModel.authenticatorTypes.spidMethod =
					loginOptions.optionModel.authenticatorTypes.spidMethod;
				state.loginOTPDetails.optionModel.authenticatorTypes.ssoMethod =
					loginOptions.optionModel.authenticatorTypes.ssoMethod;
			}

			if (loginOptions.isDirectLogin) {
				state.loginDetails = action.payload.data.directLoginResponse;
			}
			state.loginOTPDetails.accessToken = loginOptions.accessToken;
			state.loginOTPDetails.getProcessDefinitionId = loginOptions.getProcessDefinitionId;
		});

		builder.addCase(loginWithOtpAsync.fulfilled, (state, action) => {
			state.loginDetails.isSuccess = action.payload.isSuccess;
			state.loginDetails.message = action.payload.message;
			state.loginDetails.data = action.payload.data;
		});
		builder.addCase(loginWithSpidAsync.fulfilled, (state, action) => {
			state.oneTimeTokenResponse.isSuccess = true;
			state.oneTimeTokenResponse.data = action.payload;
		});
		builder.addCase(fetchIssLoggin.fulfilled, (state, action) => {
			state.loginDetails.isSuccess = action.payload.isSuccess;
			state.loginDetails.message = action.payload.message;
			state.loginDetails.data = action.payload.data;
		});
		builder.addCase(generateOTPAsync.fulfilled, (state, action) => {
			state.otpGenerationResponse.isSuccess = action.payload.isSuccess;
			state.otpGenerationResponse.message = action.payload.message;
		});
	},
});

export const { reducer: loginReducer } = loginSlice;
