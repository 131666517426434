import { LoginResponse } from './login.type';

export const loginOptionDetails: LoginResponse = {
	loginOTPDetails: {
		optionModel: {
			isSSOLogin: false,
			isSPIDLogin: false,
			isOTPLogin: false,
			isAccessTokenInvalid: false,
			isAccessTokenExpired: false,
			authenticatorTypes: {
				spidMethod: {
					spidPrivacyPolicyText: '',
					spidPrivacyPolicyFile: {
						fileBase64: '',
						fileName: '',
						fileType: '',
						minoFileLocation: '',
						namirailEnvopId: '',
						namirialUploadedDocumentResponse: { documentNumber: 0, fileId: '' },
					},

					isSpidFailedAndGotRedurect: true,
					button: '',
					spidMode: {
						naturalPerson: false,
						legalEntity: false,
						authentication: false,
					},
					title: '',
					subTitle: '',
				},
				otpMethod: {
					button: '',
					otpMessageText: '',
					otpSender: '',
					title: '',
					subTitle: '',
					phoneDescription: '',
					phoneFieldLabel: '',
					otpDescription: '',
					otpFieldLabel: '',
				},
				ssoMethod: {
					name: '',
					description: '',
					button: '',
				},
				customerChoice: {
					id: 0,
					displayValue: '',
					value: '',
					disabled: false,
				},
				externalAgentChoice: {
					id: 0,
					displayValue: '',
					value: '',
					disabled: false,
				},
			},
			processId: 0,
			bankLogoImg: '',
		},
		directLoginResponse: null,
		isDirectLogin: false,
		getProcessDefinitionId: 0,
		accessToken: '',
	},
	loginDetails: {
		isSuccess: false,
		message: '',
		data: {
			initialRouteRedirect: '',
			isLoggIn: false,
			loginUser: null,
		},
	},
	otpGenerationResponse: {
		isSuccess: false,
		message: '',
	},
	oneTimeTokenResponse: {
		data: null,
		isSuccess: false,
		message: '',
	},
};
