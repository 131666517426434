// Login url
const LOGIN_BASE_URL = 'login';
export const GET_LOGIN_OPTION = `${LOGIN_BASE_URL}/GetLoginOption`;
export const LOGIN_URL = `${LOGIN_BASE_URL}/LoginWithOTP`;
export const IS_LOGIN_URL = `${LOGIN_BASE_URL}/IsLogIn`;
export const GENERATE_OTP_URL = `${LOGIN_BASE_URL}/GenerateOTP`;
export const LOGOUT_URL = `${LOGIN_BASE_URL}/Logout`;
export const SESSION_EXPIRED = `${LOGIN_BASE_URL}/SessionExpired`;
export const GET_FILE_BASE64_FROM_MINIO_URL_FOR_LOGIN = `${LOGIN_BASE_URL}/GetFileBase64FromMinio`;

// Logging Url
const LOGGING_BASE_URL = 'logging';
export const EVENT_LOG_URL = `${LOGGING_BASE_URL}/eventLogging`;

// identification url
export const IDENTIFICATION_BASE_URL = 'identification';
export const LOGIN_WITh_SPID_URL = `${IDENTIFICATION_BASE_URL}/LoginWithSPID`;

// Layout URL
const LAYOUT_BASE_URL = 'layout';
export const GET_LAYOUT_DETAIL_URL = `${LAYOUT_BASE_URL}/GetLayoutDeatil`;
export const SET_ExternalPersonIdInCaseOfInternalAndExternalAgent = `${LAYOUT_BASE_URL}/SetExternalPersonIdInCaseOfInternalAndExternalAgent`;

// Welcome page
const WELCOME_BASE_URL = 'welcome';
export const GET_WELCOME_PAGE_DETAIL_URL = `${WELCOME_BASE_URL}/GetWelcomePageDetails`;

// Download Page
const DOWNLOAD_BASE_URL = 'download';
export const GET_DOWNLOAD_PAGE_DETAIL_URL = `${DOWNLOAD_BASE_URL}/GetDownloadPageDetails`;
export const SET_DOCUMENT_TRACKING_URL = `${DOWNLOAD_BASE_URL}/SetDocumentTracking`;
export const SET_DOCUMENT_STATUS_URL = `${DOWNLOAD_BASE_URL}/UpdateDownloadModuleStatus`;
export const GET_FILE_BASE64_FROM_MINIO_URL = `${DOWNLOAD_BASE_URL}/GetFileBase64FromMinio`;

// Tracking Page
const TRACKING_BASE_URL = 'tracking';
export const GET_TRACKING_PAGE_DETAIL_URL = `${TRACKING_BASE_URL}/GetTrackingPageDetails`;

// Upload Page
const UPLOAD_BASE_URL = 'upload';
export const GET_UPLOAD_PAGE_DETAIL_URL = `${UPLOAD_BASE_URL}/GetUploadPageDetails`;
export const GET_FILE_DETAILS_FOR_VALIDAION = `${UPLOAD_BASE_URL}/GetFileDetail`;
export const UPLOAD_FILE_DETAILS = `${UPLOAD_BASE_URL}/UploadFilesAndDetails`;
export const UPDATE_FILE_DETAILS = `${UPLOAD_BASE_URL}/UpdateFileDetails`;
export const SEND_SECTION_DETAIL_TO_SOURCE_SYSTEM = `${UPLOAD_BASE_URL}/SendDataTOSourceSystem`;
export const GET_NOTE_HISTORY = `${UPLOAD_BASE_URL}/GetNoteHistoryByIssueId`;

// Questionario Page
const QUESTIONARIO_BASE_URL = 'questionario';
export const GET_QUESTIONARIO_PAGE_DETAIL_URL = `${QUESTIONARIO_BASE_URL}/GetQuestionarioPageDetails`;
export const UPLOAD_SURVEY_DATA = `${QUESTIONARIO_BASE_URL}/SaveQuestionarioPageDetails`; //SaveAndSendDataToSourceSystem SaveNote
export const SAVE_AND_UPLOAD_SURVEY_DATA = `${QUESTIONARIO_BASE_URL}/SaveAndSendDataToSourceSystem`;
export const SAVE_QUESTION_SUREY_NOTE = `${QUESTIONARIO_BASE_URL}/SaveNote`;
export const SEND_DOCUMENT_TO_SOURCE = `${QUESTIONARIO_BASE_URL}/SendDocument`;
export const GET_SURVEY_NOTE_HISTORY = `${QUESTIONARIO_BASE_URL}/GetNoteHistoryByIssueId`;

// Firma Page
const FIRMA_BASE_URL = 'firma';
export const GET_FIRMA_PAGE_DETAIL_URL = `${FIRMA_BASE_URL}/GetFirmaPageDetails`;
export const LOG_CRITICAL_INFO_URL = `${FIRMA_BASE_URL}/LogCriticalInfoAsync`;

// Firma Page
const SELF_ID_BASE_URL = 'selfId';
export const GET_SELFID_PAGE_DETAIL_URL = `${SELF_ID_BASE_URL}/GetSelfIdPageDetails`;
export const UPLOAD_SELFID_PAGE_FILE_DETAILS = `${SELF_ID_BASE_URL}/UploadFilesAndDetails`;
export const UPLOAD_SELF_ID_PERSONAL_DATA = `${SELF_ID_BASE_URL}/SavePersonalDetails`;
export const SELF_ID_SEND_DATA_TO_SOURCE = `${SELF_ID_BASE_URL}/SendDataToSourceSystem`;
export const GET_SELF_ID_QR_DETAILS = `${SELF_ID_BASE_URL}/GetQRScanDetails`;

// Namirial API
const NAMIRIAL_BASE_URL = 'Namirial';
export const SIGN_DOC_IN_NAMIRIAL_ASYNC_URL = `${NAMIRIAL_BASE_URL}/SignDocInNamirialAsync`;
export const UPLOAD_FILE_TO_NAMIRIAL_ASYNC_URL = `${NAMIRIAL_BASE_URL}/UploadFileToNamirialAsync`;
export const SEND_ENVELOP_TO_NAMIRIAL_ASYNC_URL = `${NAMIRIAL_BASE_URL}/SendEnvelopToNamirialAsync`;
export const VIEW_ENVELOP_TO_NAMIRIAL_ASYNC_URL = `${NAMIRIAL_BASE_URL}/ViewEnvelopToNamirialAsync`;
export const DOWNLOAD_SIGNED_DOCUMENT_TO_NAMIRIAL_ASYNC_URL = `${NAMIRIAL_BASE_URL}/DownloadSignedDocumentToNamirialAsync`;
export const NAMIRIAL_CALLBACK_WORKFLOW_COMPLETED_ASYNC_URL = `${NAMIRIAL_BASE_URL}/NamirialCallbackWorkflowCompleted`;
export const NAMIRIAL_CALLBACK_LOOP_FOR_SINGLE_SIGNER_COMPLETION_ASYNC_URL = `${NAMIRIAL_BASE_URL}/GetMultiSignerViewLinkIfCuurentEnvelopCompleted`;

// Document submission to source system
export const SOURCE_SYSTEM_BASE_URL = 'SourceSystemDataSubmission';
export const SOURCE_SYSTEM_EACH_ACTION_URL = `${SOURCE_SYSTEM_BASE_URL}/SendDownloadModuleDataOnEachAction`;
