import { Loader } from 'mida-core-ui/components';
import { useEffect, useState } from 'react';
import App from './App';
import { useAppDispatch } from './store';
import { fetchIssLoggin } from './store/slice';

function AppWrapper() {
	const [isReady, setIsReady] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(fetchIssLoggin()).then(() => {
			setIsReady(true);
		});
	}, [dispatch]);

	if (!isReady)
		return (
			<div className="min-h-screen flex items-center justify-center">
				<Loader className="h-10 w-10 text-primary" />
			</div>
		);
	return <App />;
}

export default AppWrapper;
