import { createAsyncThunk } from '@reduxjs/toolkit';
import { FirmaFileModel } from '../firma';
import * as namirialAPI from './namirial.api';
import { CallBackOfSignleSigning, UploadFileRequest } from './namirial.type';

export const signDocInNamirialAsyncAction = createAsyncThunk(
	'Namirial/SignDocInNamirialAsync',
	async (uploadReq: FirmaFileModel[]) => {
		const resp = await namirialAPI.SignDocInNamirialAsync(uploadReq);
		return resp.data;
	},
);

export const uploadFileToNamirialAsyncAction = createAsyncThunk(
	'Namirial/UploadFileToNamirialAsync',
	async (uploadReq: UploadFileRequest) => {
		const resp = await namirialAPI.uploadFileToNamirialAsync(uploadReq);
		return resp.data;
	},
);

export const downlaodSignedDocumentAction = createAsyncThunk(
	'Namirial/DownlaodSignedDocument',
	async (envolopId: string) => {
		const resp = await namirialAPI.downlaodSignedDocument(envolopId);
		return resp.data;
	},
);

export const getMultiSignerViewLinkIfCuurentEnvelopCompletedAction = createAsyncThunk(
	'Namirial/GetMultiSignerViewLinkIfCuurentEnvelopCompleted',
	async (obj: CallBackOfSignleSigning) => {
		const resp = await namirialAPI.getMultiSignerViewLinkIfCuurentEnvelopCompleted(
			obj.singleSignerEnvelop,
			obj.multiSignerEnvelop,
		);
		return resp.data;
	},
);
