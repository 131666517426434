import { createAsyncThunk } from '@reduxjs/toolkit';
import * as uploadPageAPI from './upload.api';
import { UploadFileDetails } from './upload.type';

export const fetchUploadPageDetails = createAsyncThunk('Upload/GetUploadPageDetails', async () => {
	const resp = await uploadPageAPI.getUploadPageDetails();
	return resp.data;
});

export const getFileDetailsForValidationAction = createAsyncThunk(
	'Upload/GetFileDetail',
	async (formData: FormData) => {
		const resp = await uploadPageAPI.getFileDetailsForValidation(formData);
		return resp.data;
	},
);

export const uploadFileDetailsAction = createAsyncThunk(
	'Upload/UploadFilesAndDetails',
	async (formData: FormData) => {
		const resp = await uploadPageAPI.uploadFileDetails(formData);
		return resp.data;
	},
);

export const updateFileDetailsAction = createAsyncThunk(
	'Upload/UpdateFileDetails',
	async (filedata: UploadFileDetails) => {
		const resp = await uploadPageAPI.updateFileDetails(filedata);
		return resp.data;
	},
);

export const sendDataToSourceSytemAction = createAsyncThunk(
	'Upload/SendDataTOSourceSystem',
	async (uploadSectionModule: UploadFileDetails[]) => {
		uploadSectionModule.forEach(f => {
			f.fileDetail.forEach(d => (d.fileBase64 = ''));
			f.docStatus.forEach(e => {
				if (e.key == 'IsDocumentConfirm') e.value = true;
			});
		});

		const resp = await uploadPageAPI.sendDataTOSourceSystem(uploadSectionModule);
		return resp.data;
	},
);

export const getNoteHistoryByIssueIdAction = createAsyncThunk(
	'Upload/GetNoteHistoryByIssueId',
	async (documentIdentifyingCode: string) => {
		const resp = await uploadPageAPI.getNoteHistoryByIssueId(documentIdentifyingCode);
		return resp.data;
	},
);
