import { ReactNotifications } from 'react-notifications-component';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRoutes from './routes/router';
import { useAxiosLoader } from './hooks/useAxiosLoader';
import Loader from './components/Loader/Loader';

function App() {
	const [loading, interceptorsSetupComplete] = useAxiosLoader();
	if (!interceptorsSetupComplete) {
		return <Loader />;
	}
	return (
		<div>
			{loading ? <Loader /> : ''}
			<BrowserRouter>
				<ReactNotifications />
				<AppRoutes />
			</BrowserRouter>
		</div>
	);
}

export default App;
