import { QuestionarioModel } from './questionario.type';

export const questionarioPageDetails: QuestionarioModel = {
	bannerDetail: {
		description:
			'<p>Ora con IDOS puoi gestire la tua pratica in modo più autonomo, veloce ed efficace!</p>',
		title: '',
	},
	documents: [],
	surveyDetails: [],
};
