import { SelfIdModel } from './selfId.type';

export const selfIdPageDetails: SelfIdModel = {
	selectedDocument: '',
	selectedDocumentId: '',
	recordedVideo: '',
	recordedVideoId: '',
	title: null,
	backImage: '',
	frontImage: '',
	randomPhrase: '',
	docTypeToUpdate: '',
	newlySelectedDocument: '',
	newlySelectedDocumentId: '',
	backRecycle: false,
	frontRecycle: false,
	videoRecycle: false,
	personalDetailsRecycle: false,
	personalDetailsSaved: false,
	totalSteps: 0,
	qrCodeDetails: {
		url: 'https://www.idosdevs.com',
	},
	comingFromQRCode: false,
	selfie: '',
	selfieRecycle: false,
	selfieId: '',
	backImageFilePath: '',
	frontImageFilePath: '',
	selfieFilepath: '',
	videoFilepath: '',
};
