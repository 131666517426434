import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { layoutReducer, loginReducer } from './slice';
import { downloadReducer } from './slice/download';
import { firmaReducer } from './slice/firma';
import { namirialReducer } from './slice/namirial';
import { questionarioReducer } from './slice/questionario';
import { selfIdReducer } from './slice/selfId';
import { trackingReducer } from './slice/tracking';
import { uploadReducer } from './slice/upload';
import { welcomeReducer } from './slice/welcome';

export const store = configureStore({
	reducer: {
		login: loginReducer,
		layout: layoutReducer,
		welcome: welcomeReducer,
		tracking: trackingReducer,
		download: downloadReducer,
		upload: uploadReducer,
		selfId: selfIdReducer,
		questionario: questionarioReducer,
		firma: firmaReducer,
		namirial: namirialReducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch: () => AppDispatch = useDispatch;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
