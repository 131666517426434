import {
	GET_SELFID_PAGE_DETAIL_URL,
	GET_SELF_ID_QR_DETAILS,
	SELF_ID_SEND_DATA_TO_SOURCE,
	UPLOAD_SELFID_PAGE_FILE_DETAILS,
	UPLOAD_SELF_ID_PERSONAL_DATA,
} from '../../../constant/apiUrls';
import { httpClient } from '../../../service/httpClient';
import {
	IdentificationSavedData,
	PersonalDetailsData,
	QRDetails,
	SelfIdFileDetails,
} from './selfId.type';

export const getSelfIdPageDetails = async () => {
	return httpClient.get<IdentificationSavedData>(GET_SELFID_PAGE_DETAIL_URL);
};

export const uploadFileDetails = async (formData: FormData) => {
	return httpClient.post(UPLOAD_SELFID_PAGE_FILE_DETAILS, formData);
};

export const savePersonalDetails = async (personalData: PersonalDetailsData) => {
	return httpClient.post(UPLOAD_SELF_ID_PERSONAL_DATA, personalData);
};

export const sendDataToSourceSystem = async (personalData: SelfIdFileDetails[]) => {
	return httpClient.post(SELF_ID_SEND_DATA_TO_SOURCE, personalData);
};
export const getQRScanDetails = async () => {
	return httpClient.get<QRDetails>(GET_SELF_ID_QR_DETAILS);
};
