import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchFirmaPageDetails, logCriticalInfoInDb } from './firma.actions';
import { getFirmaPageDetails } from './firma.initialState';
import { FirmaCategorySelection } from './firma.type';

export const firmaSlice = createSlice({
	name: 'firmaPage',
	initialState: getFirmaPageDetails,
	reducers: {
		setFirmaCategorySelection(state, action: PayloadAction<FirmaCategorySelection>) {
			state.documents
				.filter(g => g.documentCategory === action.payload.categoryName)
				.forEach(t => {
					t.isSignatureRequired = action.payload.isRequired;
				});
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchFirmaPageDetails.fulfilled, (state, action) => {
			state.documents = action.payload.data.documents;
			state.bannerDetail = action.payload.data.bannerDetail;
		});
		builder.addCase(logCriticalInfoInDb.fulfilled, (state, action) => {
			const criticalInfo = action.payload.data;
		});
	},
});
export const { setFirmaCategorySelection } = firmaSlice.actions;
export const { reducer: firmaReducer } = firmaSlice;
